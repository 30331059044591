<template>
  <div>
    <a-card :title="$t('库位管理')">
      <a-row gutter="16">
        <a-col :span="24" :md="6" :xl="3" style="max-width: 256px; margin-bottom: 12px">
          <a-select v-model="searchForm.is_active" :placeholder="$t('状态')" allowClear style="width: 100%" @change="search">
            <a-select-option :value="true">{{ $t("激活") }}</a-select-option>
            <a-select-option :value="false">{{ $t("冻结") }}</a-select-option>
          </a-select>
        </a-col>
        <a-col :span="24" :md="6" :xl="3" style="max-width: 256px; margin-bottom: 12px">
          <a-select v-model="searchForm.warehouse" :placeholder="$t('仓库')" allowClear style="width: 100%" @change="search">
            <a-select-option v-for="item in warehouseItems" :key="item.id" :value="item.id">
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-col>
        <a-col :span="24" :md="6" :xl="4" style="max-width: 256px; margin-bottom: 12px">
          <a-input v-model="searchForm.search" :placeholder="$t('编号, 备注')" allowClear @pressEnter="search" />
        </a-col>
        <a-col :span="24" :md="8" :xl="6" style="max-width: 100px; margin-bottom: 12px">
          <a-button type="primary" icon="search" @click="search">{{ $t("查询") }}</a-button>
        </a-col>

        <a-col :span="24" style="width: 500px; margin-bottom: 12px">
          <a-button-group>
            <a-button icon="file-excel" @click="downloadTemplate">{{ $t("模板下载") }}</a-button>
            <a-upload name="file" :showUploadList="false" :customRequest="importExcel">
              <a-button icon="upload">{{ $t("导入") }}</a-button>
            </a-upload>
            <a-button icon="download" @click="exportExcel">{{ $t("导出") }}</a-button>
            <a-button icon="barcode" @click="printBatch">{{ $t("批量打印") }}</a-button>
            <a-popconfirm :title="$t('确定删除吗?')" @confirm="batchDestroy">
              <a-button type="danger" icon="delete">{{ $t("批量删除") }}</a-button>
            </a-popconfirm>
          </a-button-group>
        </a-col>
        <div style="margin-bottom: 12px; float: right">
          <a-button type="primary" icon="plus" style="margin: 0 8px" @click="openFormModal(form)">{{
            $t("新增库位")
          }}</a-button>
        </div>
      </a-row>

      <a-row>
        <a-table
          size="small"
          rowKey="id"
          :columns="columns"
          :dataSource="items"
          :loading="loading"
          :pagination="pagination"
          :rowSelection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
          @change="tableChange"
        >
          <div slot="is_active" slot-scope="value">
            <a-tag :color="value ? 'green' : 'red'">{{ value ? $t("激活") : $t("冻结") }}</a-tag>
          </div>
          <div slot="action" slot-scope="value, item">
            <a-button-group>
              <a-button icon="barcode" size="small" @click="labelPrint(item)">{{ $t("打印") }}</a-button>
              <a-button icon="edit" size="small" @click="openFormModal(item)">{{ $t("编辑") }}</a-button>
              <a-popconfirm :title="$t('确定删除吗?')" @confirm="destroy(item.id)">
                <a-button type="danger" icon="delete" size="small">{{ $t("删除") }}</a-button>
              </a-popconfirm>
            </a-button-group>
          </div>
        </a-table>
      </a-row>
    </a-card>

    <form-modal v-model="visible" :form="targetItem" :warehouseItems="warehouseItems" @create="create" @update="update" />
    <a-modal v-model="importLoading" :footer="null" :maskClosable="false" :closable="false">
      <div><a-spin style="margin-right: 12px" />{{ $t("正在导入中, 请等待...") }}</div>
    </a-modal>
  </div>
</template>

<script>
import { getLodop } from "@/assets/LodopFuncs";
import { locationList, locationDestroy } from "@/api/data";
import { warehouseOption } from "@/api/option";
// import { stockLocationList, stockLocationDestroy, stockLocationExportExcel, stockLocationImportExcel } from '@/api/manage'
import { exportExcel } from "@/utils/excel";
import { locationsExport } from "@/api/export";
import { locationsTemplate, locationsImport } from "@/api/import";
import columns from "./columns.js";

export default {
  name: "StockLocation",
  components: {
    FormModal: () => import("./FormModal.vue"),
  },
  data() {
    return {
      columns,
      searchForm: { search: "", is_active: undefined, page: 1, ordering: undefined, warehouse: undefined },
      pagination: { current: 1, total: 0, pageSize: 16 },
      loading: false,
      items: [],
      visible: false,
      targetItem: {},
      form: { is_active: true },
      importLoading: false,
      warehouseItems: [],
      selectedRowKeys: [],
      selectionRows: [],
    };
  },
  computed: {
    currentWarehouse() {
      return this.$store.state.user.currentWarehouse;
    },
    locationLabelWidth() {
      return this.$store.state.user.barConfig.locationLabelWidth;
    },
    locationLabelLength() {
      return this.$store.state.user.barConfig.locationLabelLength;
    },
  },
  methods: {
    initialize() {
      this.searchForm.warehouse = this.currentWarehouse;
      this.list();

      warehouseOption({ page_size: 999999 }).then((data) => {
        this.warehouseItems = data.results;
        this.searchForm.warehouse = this.currentWarehouse;
      });
    },
    list() {
      this.loading = true;
      locationList(this.searchForm)
        .then((data) => {
          this.pagination.total = data.count;
          this.items = data.results;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    create(item) {
      this.items.splice(0, 0, item);
    },
    update(item) {
      this.items.splice(
        this.items.findIndex((i) => i.id == item.id),
        1,
        item
      );
    },
    destroy(id) {
      locationDestroy({ id }).then(() => {
        this.items.splice(
          this.items.findIndex((item) => item.id == id),
          1
        );
        this.$message.success(this.$t("删除成功"));
      });
    },
    batchDestroy() {
      if (!this.selectedRowKeys.length) {
        this.$message.warning(this.$t("请选择要删除的数据"));
        return;
      }

      for (const key of this.selectedRowKeys) {
        this.destroy(key);
      }
    },
    search() {
      this.searchForm.page = 1;
      this.pagination.current = 1;
      this.list();
    },
    tableChange(pagination, filters, sorter) {
      this.searchForm.page = pagination.current;
      this.pagination.current = pagination.current;
      this.searchForm.ordering = `${sorter.order == "descend" ? "-" : ""}${sorter.field}`;
      this.list();
    },
    openFormModal(item) {
      this.targetItem = { ...item };
      this.visible = true;
    },
    exportExcel() {
      locationsExport(this.searchForm)
        .then((resp) => {
          exportExcel(resp, this.$t("库位列表"));
        })
        .catch((err) => {
          this.$message.error(err.response.data.error);
        });
    },
    downloadTemplate() {
      locationsTemplate()
        .then((resp) => {
          exportExcel(resp, this.$t("库位导入模板"));
        })
        .catch((err) => {
          this.$message.error(err.response.data.error);
        });
    },
    importExcel(item) {
      let data = new FormData();
      data.append("file", item.file);
      this.importLoading = true;
      setTimeout(() => {
        locationsImport(data)
          .then(() => {
            this.$message.success(this.$t("导入成功"));
            this.list();
          })
          .catch((err) => {
            alert(this.$t("导入错误:") + "\n" + err.response.data.join("\n"));
          })
          .finally(() => {
            this.importLoading = false;
          });
      }, 1000);
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },
    onClearSelected() {
      this.selectedRowKeys = [];
      this.selectionRows = [];
    },
    labelPrint(item) {
      if (!this.locationLabelLength || !this.locationLabelWidth) {
        this.$message.warning(this.$t("请先在标签配置中设置标签参数!"));
        return false;
      }
      let LODOP = getLodop();
      LODOP.PRINT_INIT("");
      LODOP.SET_PRINT_PAGESIZE(1, `${this.locationLabelLength}mm`, `${this.locationLabelWidth}mm`);
      LODOP.ADD_PRINT_BARCODE(
        `${4}mm`,
        `${4}mm`,
        `${this.locationLabelLength - 8}mm`,
        `${this.locationLabelWidth - 8}mm`,
        "128A",
        item.number
      );
      LODOP.SET_PRINT_STYLEA(0, "FontSize", 26);

      // LODOP.ADD_PRINT_BARCODE(38, 34, this.locationLabelLength, this.locationLabelWidth, "128A", item.number);
      LODOP.PREVIEW();
    },
    printBatch() {
      if (!this.items.length) {
        this.$message.warning(this.$t("暂无打印的数据!"));
        return false;
      }
      if (!this.locationLabelLength || !this.locationLabelWidth) {
        this.$message.warning(this.$t("请先在标签配置中设置标签参数!"));
        return false;
      }
      let LODOP = getLodop();
      LODOP.PRINT_INIT("");
      LODOP.SET_PRINT_PAGESIZE(1, `${this.locationLabelLength}mm`, `${this.locationLabelWidth}mm`);
      this.items.map((item, idx) => {
        LODOP.NewPage();
        LODOP.ADD_PRINT_BARCODE(
          `${4}mm`,
          `${4}mm`,
          `${this.locationLabelLength - 8}mm`,
          `${this.locationLabelWidth - 8}mm`,
          "128A",
          item.number
        );
        LODOP.SET_PRINT_STYLEA(0, "FontSize", 26);

        // LODOP.ADD_PRINT_BARCODE(38 + idx*(this.locationLabelWidth+20), 34, this.locationLabelLength, this.locationLabelWidth, "128A", item.number);
      });
      LODOP.PREVIEW();
    },
  },
  mounted() {
    this.initialize();
  },
};
</script>

<style scoped></style>
