import { T } from "@/locales";

export default [
  {
    title: T("序号"),
    dataIndex: "index",
    key: "index",
    customRender: (value, item, index) => {
      return index + 1;
    },
  },
  {
    title: T("库位编号"),
    dataIndex: "number",
    sorter: true,
  },
  {
    title: T("仓库"),
    dataIndex: "warehouse_name",
  },
  {
    title: T("库区"),
    dataIndex: "reservoir_area_name",
  },
  {
    title: T("货架/区域号"),
    dataIndex: "area_number",
  },
  {
    title: T("行号"),
    dataIndex: "row",
  },
  {
    title: T("列号"),
    dataIndex: "col",
  },
  {
    title: T("状态"),
    dataIndex: "is_active",
    scopedSlots: { customRender: "is_active" },
  },
  {
    title: T("操作"),
    dataIndex: "action",
    scopedSlots: { customRender: "action" },
    width: "216px",
  },
];
